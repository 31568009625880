import React, { useContext, useState, useMemo } from "react";
import { AuthContext } from "../../contexts/AuthContext";
import { Row, Input, Label, Col, Button, Badge, Table, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import QRCode from "react-qr-code";
import Header from "../../layout/Header/Header";
import Baja from "../Baja";
function WhatsApp() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_WHATSAPP = process.env.REACT_APP_URL_WHATSAPP;
  const URL_TELEFONOS_WHATSAPP = process.env.REACT_APP_URL_TELEFONOS_WHATSAPP;
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [qrData, setQRData] = useState("");
  const [status, setStatus] = useState("Esperando Estado...");
  const [telefonos, setTelefonos] = useState([]);

  
  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [idEdit, setIdEdit] = useState("")
  const [nameEdit, setNameEdit] = useState("")
  const [telefonoEdit, setTelefonoEdit] = useState()
  const [tipoEdit, setTipoEdit] = useState("")

  const [modalAgregar, setModalAgregar] = useState(false);
  const toggleAgregar = () => setModalAgregar(!modalAgregar);

  const [nameAgregar, setNameAgregar] = useState("")
  const [telefonoAgregar, setTelefonoAgregar] = useState()
  const [tipoAgregar, setTipoAgregar] = useState("Admin")

  useMemo(() => {
    axios
    .get(`${URL_TELEFONOS_WHATSAPP}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((response) => {
      let allTelefonos = response.data;
      setTelefonos(allTelefonos);
    })
    .catch((err) => {
      console.log(err);
    });
  },[])

  setInterval(async () => {
    axios
      .get(`${URL_WHATSAPP}Status`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allStatus = response.data.state;
        setStatus(allStatus);
      })
      .catch((err) => {
        console.log(err);
      });
    }, 10000);

  const login = async (update) => {
    let qrCodeInterval;
    try {
      // Function to fetch and update the QR code
      const fetchQRCode = async (update) => {
        try {
          const response = await axios.get(`${URL_WHATSAPP}Login`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          });
          
          // Close the Swal here after receiving the response
          await Swal.close();

          if (response.status == 200) {
            if (!response.data.ready) {
              setQRData(response.data.qr);
              if (!update) {
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: response.data.message,
                  showConfirmButton: false,
                  timer: 2000,
                });
              }
            }else{
              Swal.fire({
                position: "center",
                icon: "success",
                title: response.data.message,
                showConfirmButton: false,
                timer: 2000,
              })
            }
          }



          return response.data.ready;
        } catch (err) {
          Swal.fire({
            position: "center",
            icon: "error",
            title: err.message,
            showConfirmButton: false,
            timer: 2000,
          });
          console.log(err);
        }
      };

      // Show the loading message
      Swal.fire({
        position: "center",
        icon: "info",
        title: "Generando QR",
        showConfirmButton: false,
      });

      // Initial fetch
      await fetchQRCode(update);

      // Set up a setInterval to fetch the QR code every 30 seconds
      qrCodeInterval = setInterval(async () => {
        const result = await fetchQRCode(true);
        if (result == true) {
          setQRData("");
          clearInterval(qrCodeInterval);
        }
      }, 10000);

      // You may want to store qrCodeInterval in a state or variable to be able to clear it later if needed.
    } catch (error) {
      console.log(error);
    }
  };

  const sendMessage = async () => {
    try {
      await axios
        .post(
          `${URL_WHATSAPP}SendMsg`,
          {
            number: number,
            message: message,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          if (response.data.message == "Enviado") {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Tu mensaje ha sido enviado",
              showConfirmButton: false,
              timer: 2000,
            });
            setNumber("")
            setMessage("")
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      console.error("Error al enviar el mensaje:", error);
    }
  };

  function editTelefono(event) {
    event.preventDefault();
    axios
      .patch(
        `${process.env.REACT_APP_URL_TELEFONOS_WHATSAPP}/${idEdit}`,
        {
          name: nameEdit,
          telefono: telefonoEdit,
          tipo: tipoEdit
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Editar Telefono Whatsapp',
            detalle: nameEdit + " / " + telefonoEdit + " / " + tipoEdit,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function crearTelefono(event) {
    event.preventDefault();
    axios
      .post(
        `${process.env.REACT_APP_URL_TELEFONOS_WHATSAPP}`,
        {
          name: nameAgregar,
          telefono: telefonoAgregar,
          tipo: tipoAgregar
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios
        .post(
          URL_LOGS,
          {
            tipo:'Agregar Telefono Whatsapp',
            detalle: nameAgregar + " / " + telefonoAgregar + " / " + tipoAgregar,
            user: user.id
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        window.location.reload();
      })
       .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data.message}`,
        });
        console.log(error.response);
      });
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.whatsapp ? (
        <div className="card container col-sm-11">
          <div className="row">
            <div className="col-md-8">
              <h3 align="center">WhatsApp</h3>
              <Row>
                <Col md={6}>
                <Row>
                <Col>
                {status == "CONNECTED" ? (
                <Badge
                  color="info"
                  className="BadgeSize badge-pill">
                    {status}
                  </Badge>
                  
                ):
                <Badge
                  color="danger"
                  className="BadgeSize badge-pill">
                    {status}
                  </Badge>
            }
                  </Col>
              </Row>
              <br />
              <Row>
                <Col>
                <h4>Enviar Mensaje de Prueba</h4>
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Label>Número de Teléfono:</Label>
                  <Input
                    type="number"
                    value={number}
                    onChange={(e) => setNumber(e.target.value)}
                  />
                </Col>
                <Col md={4}>
                  <Label>Mensaje:</Label>
                  <Input
                    type="text"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </Col>
              </Row>
              <br />

              <Button className="btn" color="success" onClick={sendMessage}>
                Enviar Mensaje
              </Button>

              <Button
                className="btn"
                color="info"
                onClick={(e) => login(false)}
              >
                Generar QR
              </Button>
              <br />
              <br />

              {qrData != "" ? (
                <Col md={6}>
                  <QRCode value={qrData} />
                </Col>
              ) : (
                ""
              )}
                </Col>
                <Col md={6}>
            <h4>Telefonos Registrados</h4>
            <Button
            size="sm"
            color="danger"
            onClick={toggleAgregar}
            >
                Agregar
            </Button>
                <Table
                      striped
                      borderless
                      className="table-responsive-xl"
                    >
                      <thead>
                        <tr>
                          <th className="tituloTabla">Nombre</th>
                          <th className="tituloTabla">Telefono</th>
                          <th className="tituloTabla">Tipo</th>
                          <th className="tituloTabla">Editar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {telefonos
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                              return (
                                <tr>
                                  <td>{a.name}</td>
                                  <td>{a.telefono}</td>
                                  <td>{a.tipo}</td>
                                  <td>
                                    <Button
                                      color="info"
                                      id="Editar"
                                      size="sm"
                                      onClick={(e) => {
                                        setIdEdit(a._id);
                                        setNameEdit(a.name)
                                        setTelefonoEdit(a.telefono)
                                        setTipoEdit(a.tipo)
                                        toggleEdit();
                                      }}
                                    >
                                      <i class="far fa-edit"></i>{" "}
                                    </Button>

                                    <Baja
                                      idStatus={a._id}
                                      is_active={a.is_active}
                                      URL_BAJA={
                                        process.env.REACT_APP_URL_TELEFONOS_WHATSAPP
                                      }
                                    />
                              
                                   
                                  </td>
                                </tr>
                              );
                          })}
                      </tbody>
                    </Table>
                </Col>
              </Row>
             

              <Modal size="sm" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>Editar Telefono</ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Nombre</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={nameEdit}
            required
            onChange={(e) => {
              setNameEdit(e.target.value);
            }}
          />
            <Label className="mr-sm-2">Telefono</Label>
          <Input
            className="col-sm-12"
            type="number"
            value={telefonoEdit}
            required
            onChange={(e) => {
              setTelefonoEdit(e.target.value);
            }}
          />
            <Label className="mr-sm-2">Tipo</Label>
          <Input
            className="col-sm-12"
            type="select"
            value={tipoEdit}
            required
            onChange={(e) => {
              setTipoEdit(e.target.value);
            }}
          >
            <option value="Admin">Admin</option>
          </Input>
        </ModalBody>
        <ModalFooter>
          <Button color="success" 
          onClick={editTelefono}
          >
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalAgregar} toggle={toggleAgregar}>
        <ModalHeader toggle={toggleAgregar}>Agregar Telefono</ModalHeader>
        <ModalBody>
          <Label className="mr-sm-2">Nombre</Label>
          <Input
            className="col-sm-12"
            type="text"
            value={nameAgregar}
            required
            onChange={(e) => {
              setNameAgregar(e.target.value);
            }}
          />
            <Label className="mr-sm-2">Telefono</Label>
          <Input
            className="col-sm-12"
            type="number"
            value={telefonoAgregar}
            required
            onChange={(e) => {
              setTelefonoAgregar(e.target.value);
            }}
          />
            <Label className="mr-sm-2">Tipo</Label>
          <Input
            className="col-sm-12"
            type="select"
            value={tipoAgregar}
            required
            onChange={(e) => {
              setTipoAgregar(e.target.value);
            }}
          >
            <option value="Admin">Admin</option>
          </Input>
        </ModalBody>
        <ModalFooter>
          <Button color="success" 
          onClick={crearTelefono}
          >
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

            </div>
          </div>
        </div>
      ) : undefined}
    </>
  );
}

export default WhatsApp;
